// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-agb-tsx": () => import("./../src/pages/agb.tsx" /* webpackChunkName: "component---src-pages-agb-tsx" */),
  "component---src-pages-events-tsx": () => import("./../src/pages/events.tsx" /* webpackChunkName: "component---src-pages-events-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-info-tsx": () => import("./../src/pages/info.tsx" /* webpackChunkName: "component---src-pages-info-tsx" */),
  "component---src-pages-ooops-tsx": () => import("./../src/pages/ooops.tsx" /* webpackChunkName: "component---src-pages-ooops-tsx" */),
  "component---src-pages-order-address-tsx": () => import("./../src/pages/order-address.tsx" /* webpackChunkName: "component---src-pages-order-address-tsx" */),
  "component---src-pages-order-check-tsx": () => import("./../src/pages/order-check.tsx" /* webpackChunkName: "component---src-pages-order-check-tsx" */),
  "component---src-pages-order-paypal-tsx": () => import("./../src/pages/order-paypal.tsx" /* webpackChunkName: "component---src-pages-order-paypal-tsx" */),
  "component---src-pages-order-prepayment-info-tsx": () => import("./../src/pages/order-prepayment-info.tsx" /* webpackChunkName: "component---src-pages-order-prepayment-info-tsx" */),
  "component---src-pages-order-prepayment-tsx": () => import("./../src/pages/order-prepayment.tsx" /* webpackChunkName: "component---src-pages-order-prepayment-tsx" */),
  "component---src-pages-order-tsx": () => import("./../src/pages/order.tsx" /* webpackChunkName: "component---src-pages-order-tsx" */),
  "component---src-pages-thanks-tsx": () => import("./../src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */),
  "component---src-pages-variants-tsx": () => import("./../src/pages/variants.tsx" /* webpackChunkName: "component---src-pages-variants-tsx" */)
}

